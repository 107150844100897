import { Link } from '@remix-run/react'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PlentyBody from '#app/components/typography/plenty-body'
import { GiantLoadingSpinner } from '#app/components/ui/giant-spinner'
import { Icon } from '#app/components/ui/icon'
import { useDebounceSearch } from '#app/utils/misc.ts'
import { trackBrandClicked } from '#app/utils/tracking/amplitude/search-suggestion-brand-click'
import { trackProductNameClicked } from '#app/utils/tracking/amplitude/search-suggestion-product-name-click'
import { trackStoreClicked } from '#app/utils/tracking/amplitude/search-suggestion-store-click'

interface ISuggestion {
	result: string[]
}

interface ICategory {
	id: string
	name: string
}

interface IPage {
	id: string
	title: string
	url: string
}

interface ICategoryData {
	categories: ICategory[]
}

interface IPageData {
	result: IPage[]
}

interface IData {
	suggestions: ISuggestion
	categories: ICategoryData
	pages: IPageData
}

interface GridSuggestionsProps {
	searchTerm: string
	closeOverlay: () => void
	onSuggestionClick: (suggestion: string) => void
	brands: string[]
	productNames: string[]
}

const GridSuggestions = ({
	searchTerm,
	closeOverlay,
	onSuggestionClick,
	productNames,
	brands,
}: GridSuggestionsProps) => {
	const [data, setData] = useState<IData | null>(null)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState<string | null>(null)
	const [isNavigating, setIsNavigating] = useState(false) // State to track navigation
	const { t } = useTranslation('search')

	const debouncedSearchTerm = useDebounceSearch(searchTerm, 400)

	useEffect(() => {
		async function fetchData() {
			const encodedQuery = encodeURIComponent(searchTerm) // Use searchTerm directly without modifying it with the activeFilter

			try {
				const response = await fetch(
					`/resources/clerkSuggestion?query=${encodedQuery}`,
				)
				if (!response.ok) throw new Error('Network response was not ok')
				const jsonData = (await response.json()) as IData
				setData(jsonData)
				setLoading(false)
			} catch (error: any) {
				setError(error.message)
				setLoading(false)
			}
		}

		if (searchTerm) {
			fetchData()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchTerm])

	if (loading) return <div></div>
	if (error) return <div>Error: {error}</div>
	if (!data) return <div>No results available</div>

	const handleProductClick = (item: string) => {
		setIsNavigating(true)
		trackProductNameClicked(item)
		setTimeout(() => {
			onSuggestionClick(item)
			closeOverlay()
		}, 400)
	}

	const handleBrandClick = (item: string) => {
		setIsNavigating(true)
		trackBrandClicked(item)
		setTimeout(() => {
			onSuggestionClick(item)
			closeOverlay()
		}, 400)
	}

	const handleStoreClick = (page: IPage) => {
		setIsNavigating(true)
		trackStoreClicked(page.id, page.title)
		setTimeout(() => {
			closeOverlay()
		}, 400)
	}

	const uniqueProductNames = Array.from(new Set(productNames))

	if (isNavigating) {
		return (
			<div className="flex items-center justify-center">
				<GiantLoadingSpinner />
			</div>
		)
	}

	return (
		<div className="flex flex-col gap-2">
			<div className="flex flex-col gap-2">
				<PlentyBody size="md" className="text-beige-100">
					{t('search-suggestion')}
				</PlentyBody>
				{uniqueProductNames.map((item, index) => (
					<div key={index} className="flex items-center gap-2">
						<Icon
							className="text-body-md"
							size="lg"
							name={'magnifying-glass'}
						/>
						<PlentyBody
							size="sm"
							className="cursor-pointer"
							onClick={() => handleProductClick(item)}
						>
							{item}
						</PlentyBody>
					</div>
				))}
				{brands.map((item, index) => (
					<div key={index} className="flex items-center gap-2">
						<Icon
							className="text-body-md"
							size="lg"
							name={'brand-tag'} // Change the icon if needed
						/>
						<PlentyBody
							size="sm"
							className="cursor-pointer"
							onClick={() => handleBrandClick(item)}
						>
							{item}
						</PlentyBody>
					</div>
				))}
				{data.pages.result.map(page => (
					<div key={page.id}>
						<Link
							className="flex gap-2"
							to={`stores/${page.id}`}
							onClick={() => handleStoreClick(page)}
						>
							<Icon className="text-body-md" size="lg" name={'store'} />
							<PlentyBody size="sm" className="cursor-pointer">
								{page.title}
							</PlentyBody>
						</Link>
					</div>
				))}
			</div>
		</div>
	)
}

export default GridSuggestions
